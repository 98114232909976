import React from 'react';
import './TitleSection.scss';

const TitleSection = ({ title, subtitle}) => {
    return (
        <article className="TitleSection">
            <div className="content">
                <h1>{title}</h1>
                <div className="subtitle">{subtitle}</div>
            </div>
        </article>
    );
}

export default TitleSection;
