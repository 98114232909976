import * as React from "react"
import Navbar from "../../components/Navbar";
import Helmet from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"
import TitleSection from "../../components/TitleSection";
import Footer from "../../components/Footer";
import ButtonLink from "../../components/ButtonLink";

// markup
const DataImporteren = () => {
    return (
        <>
            <Helmet>
                <title>Careweb | Data importeren</title>
                <meta name="description" content="Nieuw bij Careweb en heb je veel bestaande data, dit kunnen wij voor je importeren."/>
                <meta property="og:title" content="Careweb | Data importeren"/>
                <meta property="og:url" content="https://meneerjansen-ict.nl/careweb/data-importeren"/>
                <meta property="og:description" content="Zo veel mogelijk data uit je vorige systeem mee naar Careweb."/>
                <meta property="og:image" content="https://meneerjansen-ict.nl/images/logo.png"/>
                <body className="page-website-integration" />
            </Helmet>

            <Navbar/>

            <main>

                <article className="bg-circle">
                    <TitleSection title="Data importeren" subtitle="Data van je vorig systeem beschikbaar in Careweb zonder handmatige invoer"/>

                    <div className="content">
                        <div className="cols">

                            <div className="col-xs-100 col-md-50 col-lg-33">
                                <StaticImage src="../../images/data-importeren.jpg" alt="Afbeeling van eenen en nullen"/>
                            </div>

                            <div className="col-xs-100 col-md-50 col-lg-66 ">
                                <section className="text-based">
                                    <p>
                                        Alle data uit het vorige systeem in Careweb beschikbaar.
                                    </p>
                                    <p>
                                        Cliënten, EPD data en registratie beschikbaar in Careweb zonder deze handmatig te hoeven invoeren.
                                    </p>

                                    <h3 style={{ marginTop: '2em'}}>Voordelen</h3>
                                    <ul className="check">
                                        <li>Bespaar veel tijd</li>
                                        <li>Minder foutgevoelig</li>
                                        <li>Niets gaat verloren</li>
                                        <li>Opgebouwde history blijft beschikbaar</li>
                                    </ul>

                                </section>

                            </div>

                        </div>
                    </div>
                </article>

                <article>
                    <div className="content">

                        <section className="text-based" style={{ marginBottom: '0'}}>
                            <div className="cols">
                                <div className="col-xs-100">
                                    <h2 className="sm-fsize-70">Keuze data importeren</h2>

                                    <p>
                                        Een data import is mogelijk vanaf 1.099,- excl. btw.
                                    </p>
                                    <p>
                                        Neem contact op om meer te weten te komen over het importeren van gegevens uit uw oude systeem.
                                    </p>

                                    <section className="ButtonContainer">
                                        <ButtonLink label="Neem contact op" to="/contact"/>
                                    </section>

                                </div>
                            </div>
                        </section>

                    </div>
                </article>

            </main>

            <Footer />
        </>

    )
}

export default DataImporteren;
