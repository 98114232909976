import React from 'react';
import {Link} from "gatsby";
import './ButtonLink.scss';

const ButtonLink = ({ label, to }) => {
    return (
        <Link to={to} className="ButtonLink">
            {label}
        </Link>
    );
}

export default ButtonLink;